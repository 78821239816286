// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xVoTn{height:100vh}.xVoTn .JFwLg{position:fixed!important}.xVoTn .JFwLg>div{align-items:center!important;display:flex!important;flex-direction:column!important;height:100%!important;justify-content:center!important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `xVoTn`,
	"pageDimmer": `JFwLg`
};
module.exports = ___CSS_LOADER_EXPORT___;
